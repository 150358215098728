.commissionActivitySection{
    .headerSection {
        position: relative;

        height: 40px;
        .title{
            color: $grayColor
        }
    }
    .commissionActivity{
        gap: 16px;
        .activitySection{
            width: 20%;

            font-size: $textSizeNormal;
            text-align: center;

            border-right: 2px solid $borderColor;

            .activityValue{
                color: $primaryColor;
            }
        }
    }
}
