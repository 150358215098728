.awaitingPayments{
    min-height: 471px;
    .searchArea{
        .salesPersonIp{
            height: 36px;

            border: 1px solid $borderColor;
        }
        .statusDropDown{
            height: 33px;
        }
    }
}
