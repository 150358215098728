.customer-deatil-tabs {
    .min-upper-height {
        min-height: 400px;
    }

    .min-lower-height {
        min-height: 520px;
    }

    .tablist-section {
        background: #fff;
        border-radius: 5px 5px 0 0;

        .nav-tabs {
            display: grid;
            grid-gap: 7px;
            grid-template-columns: repeat(5, 1fr);

            background: #f6f6f6;
            border: 0;

            .nav-link {
                position: relative;

                padding: 7px 10px;

                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                line-height: 26.4px;
                text-align: center;

                background: #f6f6f6;
                border: 2px solid #e2e2e2;
                box-shadow: none;

                &:after {
                    position: absolute;
                    top: 22px;
                    right: 15px;

                    display: none;
                    width: 10px;
                    height: 7px;

                    background: url(../../img/arrow-top.png);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            .nav-link.active {
                position: relative;

                background: #fff;
                border-bottom: 0;
                box-shadow: none;

                &:after {
                    top: 22px;

                    transform: rotate(180deg);
                }
            }

            li {
                &:first-child {
                    margin-left: 12px;
                }
            }
        }

        .tab-content {
            background: transparent;
            border: 2px solid #e2e2e2;
            box-shadow: none;

            .inner-detail-tab {
                ul {
                    padding: 30px 60px;

                    background: #fff;

                    li {
                        display: inline-block;
                        margin-right: 75px;
                        padding: 0 0 8px 0;

                        color: #000;
                        font-weight: bold;
                        font-size: 16px;
                        text-decoration: none;
                    }

                    li.current {
                        position: relative;

                        color: #dc1445;

                        background: transparent;

                        &:before {
                            position: absolute;
                            right: 0;
                            bottom: -1px;
                            left: 0;

                            display: inline-block;
                            width: 100%;
                            height: 2px;

                            background: #dc1445;

                            content: "";
                        }
                    }
                }
            }
        }

        .inner-tabel-tab {
            width: 100%;
            margin: 0 auto;
        }
    }

    .detail-bank-section {
        margin-top: 30px;

        .form-group {

            /* stylelint-disable-next-line */
            label {
                margin-bottom: 0;
                padding-left: 20px;

                font-size: 14px;
            }
        }

        /* stylelint-disable-next-line */
        form {
            display: grid;
            grid-gap: 20px 50px;
            grid-template-columns: repeat(2, 1fr);
            width: 70%;
            padding: 0 60px;

            .bottom-btn-section {
                display: flex;
                flex-wrap: wrap;
                grid-row: 5/5;
                grid-column: 1/3;

                justify-content: flex-end;

                button {
                    padding: 10px 45px;

                    color: #fff;
                    font-size: 12px;

                    border: 0;
                    border-radius: 30px;
                }

                .verify-btn {
                    button {
                        background: #3aaa35;
                    }
                }

                .update-btn {
                    button {
                        background: #706f6f;
                    }

                    margin-left: 15px;
                }
            }

            /* stylelint-disable-next-line */
            .update-btn {

                /* stylelint-disable-next-line */
                label {
                    margin-bottom: 15px;
                    padding-left: 50px;
                }
            }
        }
    }

    .inner-bank-tab {
        .top-header-section {
            padding: 30px 60px;

            background: #fff;

            h2 {
                position: relative;

                display: inline-block;

                color: #dc1445;
                font-weight: bold;
                font-size: 16px;

                &:after {
                    position: absolute;
                    right: 0;
                    bottom: -1px;
                    left: 0;

                    display: inline-block;
                    width: 100%;
                    height: 2px;

                    background: #dc1445;

                    content: "";
                }
            }
        }
    }

    .new-design-table-sec {
        .inner-tabel-tab {
            padding-top: 0;
        }
    }

    .application-section {
        @media (max-width: 991px) {
            width: 92%;
        }

        .inner-tabel-tab {
            .upper-section {
                margin-bottom: $mainContentSpacing;

                .tracker {
                    display: flex;
                    grid-gap: 28px;

                    @media (min-width: 1600px) {
                        grid-gap: 85px;
                    }

                    @media (max-width: 991px) {
                        grid-gap: 5px;
                    }

                    @media (max-width: 480px) {
                        display: block;
                    }

                    .tracker-div {
                        .complete {
                            padding: 10px 0;

                            color: #3aaa35;

                            font-size: 10px;

                            text-align: center;
                        }

                        .tracker-component {
                            position: relative;

                            display: flex;

                            flex-direction: column;

                            box-sizing: border-box;
                            width: 135px;
                            min-height: 118px;

                            padding: 6px;

                            font-size: 8px;

                            border: 1px solid #dfdfdd;

                            @media (max-width: 991px) {
                                width: 100px;
                                height: 100px;
                            }

                            &::after {
                                position: absolute;

                                top: calc(50% - 2px);
                                left: 100%;

                                display: block;

                                width: 30px;
                                height: 1px;

                                background: #dfdfdf;

                                content: " ";

                                @media (min-width: 1600px) {
                                    width: 86px;
                                }

                                @media (max-width: 991px) {
                                    width: 7px;
                                }
                            }

                            p {
                                padding-top: 5px;

                                font-size: 12px;
                                line-height: 14px;
                                text-align: center;
                            }

                            img {
                                //width: 48px;
                                height: 48px;
                                margin: 0 auto;

                                @media (max-width: 991px) {
                                    //width: 40px;
                                    height: 40px;
                                }
                            }
                        }

                        .last-tracker-component {
                            display: flex;
                            flex-direction: column;
                            width: 135px;
                            min-height: 118px;

                            padding: 6px;

                            font-size: 8px;

                            border: 1px solid #dfdfdd;

                            @media (max-width: 991px) {
                                width: 100px;
                                height: 100px;
                            }

                            p {
                                padding-top: 5px;

                                font-size: 12px;
                                line-height: 14px;
                                text-align: center;
                            }

                            img {
                                //width: 54px;
                                height: 48px;
                                margin: 0 auto;

                                @media (max-width: 991px) {
                                    //width: 40px;
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
            }

            h2 {
                margin-bottom: 20px;
            }
        }
    }
}

.applicant input:disabled {
    background: #fff !important;
}

.customer-applicant {
    margin-top: 35px;

    .container {
        max-width: 100%;
        max-height: 42px;

        padding: 0;
    }

    .customer-detail-header {
        margin-top: -55px;
        padding-bottom: 0;

        padding-left: 337px;

        @media (max-width: 1500px) {
            padding-left: 150px;
        }

        @media (max-width: 991px) {
            padding-left: 70px;
        }

        @media (max-width: 480px) {
            padding-left: 0;
        }

        background: #ededed;
    }

    .application-detail-info {
        display: inline-block;
        height: 38px;

        margin: 0 5px;

        .customer-detail {
            margin: 0 11px;
            padding: 12px;

            font-weight: 500;

            font-size: 12px;

            background: none !important;

            border: none !important;
            box-shadow: none;
        }

        .menu-item {
            &:hover {
                border-bottom: 2px solid #307aff;
            }
        }

        .menu-item.active {
            color: #307aff;

            border-bottom: 2px solid #307aff;
        }
    }
}

/* stylelint-disable-next-line */
.application-section {
    background: #fff;

    /* stylelint-disable-next-line */
    .form-group {

        /* stylelint-disable-next-line */
        label {
            padding: 9px 3px;

            color: #828282;
        }

        .positive-btn {
            float: right;
            margin-top: 2px;
            padding: 9px 30px;

            color: #fff !important;

            font-weight: 400;

            font-size: inherit;

            line-height: 20.12px;

            text-transform: capitalize;

            background: #307aff !important;

            border: 1px solid #307aff !important;

            border-radius: 5px;

            box-shadow: none !important;
        }

        .com-btn {
            float: right;
            margin-top: 2px;
            margin-left: 10px;
            padding: 9px 30px;

            color: #000;
            font-weight: 400;
            font-size: inherit;

            line-height: 20.12px;

            text-transform: capitalize;

            background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);

            border: 1px solid #d8d8d8;

            border-radius: 5px;

            box-shadow: 1px 1px 5px -2px #888888;
        }
    }

    .bank-otp {
        display: none;
    }

    .bank-otp.active {
        display: block;
    }

    .thankyou {
        display: none;

        color: #307aff;
    }

    .thankyou.active {
        display: block;
    }

    .bank-details-container,
    .bank-details-subpart1-container,
    .location-container,
    /* stylelint-disable-next-line */
    .address-container {
        padding-bottom: 5px;

        /* stylelint-disable-next-line */
        .detail-form-section {
            .positive-btn {
                float: right;
                padding: 9px 30px;

                border-radius: 5px;
            }

            button.com-btn {
                margin-left: 10px;
                padding: 9px 30px;

                color: #000;
                font-weight: 400;
                font-size: inherit;
                line-height: 20.12px;
                text-transform: capitalize;

                background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                box-shadow: 1px 1px 5px -2px #888888;

                /* stylelint-disable-next-line */
                img {
                    position: relative;
                    top: 2px;

                    width: 13px;
                }

                span {
                    margin-left: 8px;
                }
            }

            /* stylelint-disable-next-line */
            h1 {
                padding: 9px 0;
            }

            /* stylelint-disable-next-line */
            label {
                padding: 9px 0;
            }
        }
    }

    /* stylelint-disable-next-line */
    .bank-details-subpart1-container {
        padding-bottom: 20px;

        /* stylelint-disable-next-line */
        h2 {
            margin-bottom: 38px;
        }

        /* stylelint-disable-next-line */
        p {
            margin-bottom: 28px;
        }
    }

    .edit {
        display: none;
    }

    .edit.active {
        display: block;
        padding-top: 20px;

        .input-tick {
            position: relative;

            &:after {
                position: absolute;
                top: 65%;
                right: 25px;

                display: inline-block;
                width: 13px;
                height: 13px;

                background-repeat: no-repeat;
                background-size: 100%;

                content: "";
            }
        }
    }

    /* stylelint-disable-next-line */
    .applicants-container {

        /* stylelint-disable-next-line */
        h2 {
            padding: 0 0 30px 2px;
        }

        .table {
            .table-head {
                color: $primaryColor;

                font-weight: 100;

                background-color: #f6f6f6;

                border: 2px solid #dee2e6;
            }

            tbody {
                tr {
                    background-color: #fff;
                    cursor: pointer;
                }
            }
        }
    }

    .scroll {
        width: 100%;
        margin: 80px auto 0 auto;

        color: #307aff;
        font-size: 16px;
        text-align: center;

        .top {
            margin-top: 10px;
        }

        /* stylelint-disable-next-line */
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.product-list-section {

    /* stylelint-disable-next-line */
    ul {
        @media (max-width: 480px) {
            align-items: normal;
            padding-left: 0;
        }

        /* stylelint-disable-next-line */
        li {
            @media (max-width: 480px) {
                width: 33%;
                padding: 0;
            }

            @media (max-width: 991px) {
                width: 22%;
            }
        }
    }
}

/* stylelint-disable-next-line */
.realtimeTrackingContent {
    display: flex;
    justify-content: space-around;

    border: 1px solid #dfdfdf;

    .trackerElement {
        display: flex;
        align-items: center;

        height: 50px;

        .progressBar {
            display: flex;

            width: 7px;
            height: inherit;

            overflow: hidden;
        }

        &:nth-child(2) {
            .progressBar {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
        &:last-child {
            .progressBar {
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }

        .contentSection {
            display: flex;
            align-items: center;

            width: 100%;

            .contentHeading {
                display: flex;
                align-items: center;

                width: 65%;

                .emptyBlock {
                    width: 50px;
                }

                /* stylelint-disable-next-line */
                span {
                    color: $primaryColor;

                    font-weight: 600;
                    font-size: $textSizeVerySmall;
                }
            }

            .completedAtHead {
                width: 37%;

                color: $primaryColor;

                font-weight: 600;
                font-size: $textSizeVerySmall;
            }

            .content {
                display: flex;
                align-items: center;

                width: 65%;

                .statusCompleted{
                    background: $primaryColor;
                }
                .awaitingIcon {
                    border: 2px solid #ddd9d9;
                }

                /* stylelint-disable-next-line */
                img {
                    max-width: 36px;

                    border-radius: 20px;
                }

                /* stylelint-disable-next-line */
                span {
                    // color: $primaryColor;

                    font-weight: 500;
                    font-size: $textSizeVerySmall;
                }
            }

            .completionDate {
                width: 25%;

                font-weight: 500;
                font-size: $textSizeVerySmall;
            }
        }
    }
}

.appTrackerHead {
    display: flex;
    width: 100%;
}

.verticalTrackingSeparator {
    width: 80%;
    margin: 30px 15px 5px 15px;

    border-top: 2px solid $borderColor;
}
