.tableContainer {
    &.default {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.bordered {
            .tableHeaderPrimary {
                border: 1px solid $primaryColor;
            }

            tbody {
                tr {
                    border: 1px solid $borderColor;

                    &.noBorder{
                        border-bottom: none;
                    }
                }
            }
        }

        &.minHeight {
            min-height: $tabPanelMinHeight - 59px;
        }
        .table {

            &.selectProductTableLayout{
                .col1{
                    width:260px;
                }
                .col2, .col3{
                    width:250px;
                }
                .col4, .col5{
                    width:186px;
                }
            }

            &.standardSpacing {
                margin-bottom: $tableSpacing;
            }

            th {
                position: relative;

                height: 34px;

                padding: 5px 10px;

                color: $primaryColor;
                font-weight: normal;

                font-size: 12px;
                white-space:nowrap;
                vertical-align: middle;
                &:after {
                    display: none;

                    opacity:0;
                }
            }
            .eqaulSpace {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                align-items: center;
                justify-content: center;

                text-align: center;

                th {
                    padding: 11px;
                }
            }

            .greenCol {
                color:#3AAA35;
            }
            /* stylelint-disable-next-line */
            td {
                height: 43px;
                padding: 9px 12px;

                color: $Grey2-clr;
                font-weight: normal;
                // font-size: $textSizeNormal;
                font-size: 11px;
                vertical-align: middle;

                &.monthlyPrice{
                    position: relative;
                    left: 16px;
                }

                .table{
                    tr:nth-last-child{
                        border-bottom: none;
                    }
                    td{
                        &.noPadding{
                            padding:0;
                        }
                        padding:0;
                    }
                }
            }

            thead {
                color: $primaryColor;
                font-weight: normal;

                background: #f6f6f6;

                &.tableHeaderPrimary {
                    background-color: $primaryColor;

                    &.border {
                        border: 1px solid $primaryColor;
                    }

                    th {
                        color: white !important;

                        background-color: #317aff;
                        .tool {
                            @include tool;
                            .tooltipText {
                                @include tooltip;
                                white-space: break-spaces;

                                &.residualDetialFee{
                                    top: 32px;
                                    left: -169px;
                                }
                                &.residualDetialProfit{
                                    top: 32px;
                                    left: -303px;
                                }
                            }
                            .residualImage{
                                top: 32px;
                                right: -90px;
                                left:unset;


                                width: 225px;
                            }

                            .commissionImage{
                                top: 32px;
                                left: -92px;

                                width: 200px;
                            }

                            &:hover {
                                .tooltipText {
                                    @include tooltipHover;
                                }
                            }

                            .questionMark{
                                width : 14px;
                                &.residualDetail{
                                    top: 2px;
                                }

                            }


                        }
                    }
                }

                &.outline {
                    th {
                        border-bottom: $standardBorderWidth solid $primaryColor !important;
                    }
                }
            }

            tbody {
                .detail-table {
                    &:hover {
                        background: transparent;
                        cursor: default;
                    }

                    &.active {
                        background: #f6f6f6;
                        cursor: pointer;
                    }
                }
            }
        }


        .bottom-table-foot {
            &.borderBottom {
                border-bottom: 1px solid #dadada;
            }

            &.stretch {
                margin: 0 (-$tableSpacing);
            }

            &.removeFooterSpacing {
                margin-bottom: -$tableSpacing;
            }

            ul {
                display: grid;
                grid-auto-flow: column;
                grid-template-columns: .5fr 1fr 1fr;

                li {
                    padding: 8px 0;

                    color: #7e796f;
                    font-size: 11px;
                    line-height: 14px;

                    select {
                        border: none;
                    }

                    &:last-child {
                        height: 100%;

                        border-right: 0;
                    }

                    &:nth-child(2) {
                        display: flex;
                        justify-content: flex-start;

                        width: 100%;

                        select {
                            width: 50px;
                            margin: 0 5px;
                            padding: 0 5px;
                        }
                    }

                    &:nth-child(1) {
                        width: 260px;
                        padding-left: 10px;

                        select {
                            width: 48px;
                            margin: 0 5px;
                            padding: 0 5px;
                        }
                    }
                }

                .next-prev-btn {
                    position: relative;

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;

                    padding: 0;

                    .number {
                        position: relative;

                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 15px;

                        color: #0078c8;

                        &:before {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;

                            width: 1px;
                            height: 100%;

                            content: "";
                        }
                    }

                    .next-btn {
                        position: relative;

                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 15px;

                        color: #0078c8;

                        a {
                            color: #7e796f;
                            text-decoration: none;
                        }

                        &:after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;

                            width: 1px;
                            height: 100%;

                            content: "";
                        }
                    }

                    .end-btn {
                        a {
                            color: #7e796f;
                            text-decoration: none;
                        }

                        padding: 0 15px;
                    }
                }
            }

            margin-top: 10px;

            background: #fff;
            border-top: 1px solid #dadada;
        }
    }
}

.detailTable{
    width: 550px;
    .overFlowVisible{
        overflow: visible;
    }
}

.customerTableContainer{
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;

    justify-content: space-between;

    column-gap: 15px;
    row-gap: 15px;
}

.removeBorder{
    height: 34px  !important;

    border: 0 !important;
}
