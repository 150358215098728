.cardFeesComponent{
    .feesImg{
        margin-right: 0;

        &.fasterPayment{
            margin-left: 0;
            margin-right: -4px;
            img{
                width: 40px;
            }
        }
        &.pciPlus{
            position: relative;

            margin-right: 39px;
            margin-left: 0;

            img{
                position: absolute;
                top: -17px;

                width: 40px;
                margin-top: 10px;
            }
        }
    }
}
