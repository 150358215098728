.dBlock {
    display: block !important;
}
.dFlex{
    display: flex;
    &.dCenter{
        align-items: center;
        justify-content: center;
    }
    &.dAlignItemsCenter{
        align-items: center;
    }
    &.flexDirectionCol{
        flex-direction: column;
    }

}
.dAlignEnd{
    align-items: flex-end;
}
.dJustifyEnd{
    justify-content: flex-end;
}
.dJustifyBetween{
    justify-content: space-between;
}

@for $i from 0 through 50 {
    .gap-#{$i} {
        gap: #{$i}px;
    }
}
