.gridTableContainer {
    display: grid;
    width: 100%;

    &.default {
        grid-template-columns: 1.3fr 1.7fr 1fr 1fr 1fr;
    }
    &.eposPaymentSection {
        grid-template-columns: 1.3fr 1.7fr 1fr 1fr 1fr 1fr 0.1fr 1fr 1fr 1fr;
    }

    &.pos-products-summary {
        grid-template-columns: 2.2fr 1.7fr 1fr 1fr 1fr 1fr 1fr;

        img {
            width: 40px;

            &.fees-icon {
                width: 40px;
                padding-right: 9px;
                padding-left: 8px;
            }
        }
    }

    .gridTableHeader,
    .gridTableBody {
        display: contents;

        .noRecords {
            grid-column: span 10; /* Ensure it spans all columns */
            height: 43px;
            padding: 12px;

            color: $grayColor;
            font-style: italic;

            border-bottom: 1px solid $borderColor;
        }
    }

    .gridTableHeaderItem {
        padding: 10px;

        color: white;
        font-weight: normal;
        font-size: 12px;
        white-space: nowrap;
        text-align: left;

        background-color: #317aff;
        border-bottom: 1px solid #dfdfdf;

        &.girdSpanCol {
            grid-column: span 2;
        }
    }

    .gridTableBodyItem {
        padding: 10px;

        font-size: 12px;
        text-align: left;

        border-bottom: 1px solid #dfdfdf;

        &.girdSpanCol {
            grid-column: span 2;
        }
    }
}
