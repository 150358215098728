.ng-dropdown-panel{
    &.selectServices {
        width: 70px !important;
        min-width: 70px !important;
    }
}

.ng-select-container{
    .ng-value-container{
        .ng-value{
            color:$charcoalColor;
            font-size: 11px;
        }
    }

    .ng-clear-wrapper{
        display:none;
    }
}

.ng-select{
    .ng-arrow-wrapper {
        padding: 5px;
    }

    &.normalWidth{
        width: 70px;
    }

    &.selectServices{
        .ng-select-container{
            width: 58px;
            min-height: 20px !important;
            max-height: 20px !important;
        }
        .ng-value-container{
            margin-top: 1px;
            padding: 6px;

        }
        .ng-option{
            width: 58px !important;
            min-height: 20px !important;
            max-height: 20px !important;
        }
    }

    &.bgPrimary{
        padding:15px;

        border:none;
        .ng-select-container{
            color:$whiteColor;
            .ng-value-container{
                .ng-value{
                    color: #fff;
                    font-weight: bold;
                    font-size:18px;
                }
            }
            background-color: transparent;
            border: none;
        }
    }
    .ng-dropdown-panel{
        .ng-option-label{
            font-weight:500 !important;
        }
    }

    &.single{
        .ng-select-container{
            width:47px !important;

            border: none!important;

            .ng-arrow-wrapper{
                display:none;
            }
        }
        .ng-dropdown-panel{
            display:none;
        }
    }

    &.moto{
        .ng-dropdown-panel{
            right: 0;
            left: unset;

            width: 58px !important;
            margin-top: -5px;
        }
    }

    &.productQuantity{
        .ng-dropdown-panel{
            right: 0;
            left: unset;

            width: 58px !important;
        }
        .ng-select-container{
            min-height: 20px !important;
            max-height: 20px !important;
        }
        .ng-option{
            width: 58px !important;
            min-height: 20px !important;
            max-height: 20px !important;
        }
    }

    &.standardFee2{
        .ng-dropdown-panel{
            width:58px !important;
        }
        .ng-select-container{
            min-height: 20px !important;
            max-height: 20px !important;
        }
        .ng-option{
            width: 58px !important;
            min-height: 20px !important;
            max-height: 20px !important;
        }
    }

    &.standardFee1{
        .ng-dropdown-panel{
            width:58px !important;
        }
    }

    &.estimatedTurnover{
        .ng-dropdown-panel{
            width: 105px !important;
            margin-top: 3px;
        }
    }
}

#select-mcc{
    &.ng-invalid{
        .ng-select-container{
            .ng-placeholder{
                display:block;
            }
        }
    }

    &.ng-select-filtered{
        .ng-select-container{
            .ng-placeholder{
                display:none;
            }
        }
    }

    &.ng-valid{
        .ng-select-container{
            .ng-placeholder{
                display:none;
            }
        }
    }
}
.formStandardSpacing{
    margin-top: $standardSpacing;
}


