.gridTableLayout {
    display: grid;
    grid-template-rows: auto 1fr auto; /* Header, Body, Footer */
    box-sizing: border-box;
    width: 100%;
    min-height: 471px;

    /* Shared column template for header and rows */
    .gridHeader,
    .gridRow {
        display: grid;
        &.col-10{
            grid-template-columns: 1.2fr 1.2fr 1.5fr 1fr 1fr 1.3fr 0.1fr 1.3fr 1fr 0.5fr;
        }
        box-sizing: border-box;
        width: 100%;
    }

    /* Header styling */
    .gridHeader {
        color: $whiteColor;

        background-color: $primaryColor;

        .gridHeaderItem {
            display: flex;
            align-items: center;
            justify-content: left;
            min-width: 0; /* Prevents overflow issues */
            padding: 10px;

            font-size: $textSizeNormal;
            white-space: nowrap;
        }
    }

    /* Body styling */
    .gridBody {
        display: flex;
        flex-direction: column;
        align-content: start;
        width: 100%;
        min-height: 320px;

        .gridRow {
            border-bottom: 1px solid $borderColor;

            .gridBodyItem {
                display: flex;
                align-items: center;
                min-width: 0; /* Ensures content doesn't break grid */
                padding: 10px;
                overflow: hidden;

                font-size: $textSizeSmall;
                white-space: nowrap;
            }
        }
        .noRecords {
            height: 43px;
            padding: 12px;

            font-style: italic;
            text-align: center;

            border-bottom: 1px solid $borderColor;
        }

    }
    /* Footer styling */
    .gridTableFooter {
        margin: 0 -32px;

        border: none;
    }
}
