.rate-percent {
    margin-right: 5px;
}

.questionsSection {
    width: 100%;
}

/* stylelint-disable-next-line */
.businessQuestions {
    width: 80%;

    .question {
        p {
            // font-weight: 500;
            color: #4c4c4c;
            font-size: $textSizeNormal;
        }

        .amountBox {
            display: flex;

            align-items: center;

            justify-content: space-around;

            width: 23%;
            min-width: 105px;
            max-width: 110px;

            height: 35px;

            background-color: #fff;

            border: 1px solid #dfdfdf;

            .amountInput {
                width: 70px;

                padding-left: 3px;

                color: #000;

                font-size: 12px;

                border: 0;

                &:focus {
                    outline: none;
                }
            }

            .rate-percent {
                margin-right: 5px;

                font-size: $textSizeNormal;
            }
        }

        .answerSelection {
            display: flex;
            gap: 45px;
            justify-content: flex-start;
            margin-left: 20px;
            .answer {
                display: flex;
                align-items: center;

                label {
                    color: #4c4c4c;
                    font-size: $textSizeNormal;
                }
                input{
                    cursor: pointer;
                }

            }
        }

        .quaranteesTextarea {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 450px;
            min-height: 99px;
            padding: 5px;

            color: $Black-clr;
            font-size: $textSizeNormal;

            background-color: $whiteColor;
            border: 1px solid $borderColor;

        }


        .warrentyQuestionInput{
            padding: 8px 15px;

            color: $blackColor;
            font-size: $textSizeNormal;

            border: 1px solid $borderColor;
        }
    }

    .quarantees-textarea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 450px;

        min-height: 99px;
        padding: 5px;

        color: #000;


        font-size: 12px;


        background-color: rgb(255, 255, 255);


        border: 1px solid rgb(223, 223, 223);


    }
}


.businessContactAddress {
    /* stylelint-disable-next-line */
    input {
        width: 100%;
        height: 38px;

        padding: 10px 15px;

        font-size: 12px;

        border: 1px solid #d7d7d6;
        border-radius: 5px;
    }
}

.motoSelect {
    .ng-select {
        .ng-select-container {
            width: 58px;
            min-height: 29px;
            max-height: 29px;

            border-radius: 0;

            .ng-value-container {
                padding: 0;
                overflow: visible !important;

                .ng-placeholder {
                    padding-left: 7px;
                }

                .ng-value {
                    padding-left: 10px;
                }

            }
        }
    }
}

.businessTypeQuestions {
    width: 100%;

    .onlinePresenceSection {
        h3 {
            font-weight: 400;
            font-size: $titleFontSize;
        }
        /* stylelint-disable-next-line */
        input {
            float: none;
            width: 100%;
            height: 38px;
            padding: 9px 15px;

            font-size: 12px;

            border: 1px solid #d7d7d6;
            border-radius: 2px;

            ::placeholder {
                font-weight: 500;
            }
        }
    }

    .question {
        p {
            color: $grayColor;
            font-size: $textSizeNormal;
            font-family: Roboto, sans-serif;
        }

        .ng-select {
            width: 100%;
        }

        .tradedDate {

            /* stylelint-disable-next-line */
            label {
                font-size: $textSizeNormal;
                font-family: Roboto, sans-serif;
            }

            /* stylelint-disable-next-line */
            input {
                float: none;
                width: 100%;
                height: 38px;
                padding: 9px 15px;

                font-size: $textSizeNormal;

                border: 1px solid #d7d7d6;
                border-radius: 2px;
            }

        }

        .answerSelection {
            display: flex;
            justify-content: space-around;

            width: 40%;

            .answer {
                display: flex;
                align-items: center;

                label {
                    // font-weight: 500;
                    font-size: $textSizeNormal;
                }

                /* stylelint-disable-next-line */
                input[type=radio] {
                    width: 4px;
                    height: 4px;

                    padding: 0.25em;

                    border-radius: 6px;

                    outline: 0.1em solid #888888;
                    outline-offset: 2px;
                    cursor: pointer;

                    -webkit-appearance: none;


                }

                input[type=radio]:checked {
                    display: inline-block;

                    background-color: $colorBlue;
                    outline: 0.1em solid $colorBlue;
                }
            }
        }
    }

    .onlinePresenceUrlInfo {
        margin-top: 18px;

        font-size: $textSizeNormal;
        font-family: Roboto, sans-serif;
    }
}

.customerRegisterContainer {
    min-height: 900px;
    padding: 0 32px;

    .crSelectProduct {
        .ng-select {

            /* stylelint-disable-next-line */
            .ng-value {
                color: $blackColor;
            }
        }
    }

    .hintAndTips {
        margin: 0;
        padding: 30px 44px;

        color: $grayColor;
        font-size: 12px;
        line-height: 20px;

        border: 1px solid $primaryColor;
        border-radius: 10px;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        span {
            font-weight: 500;
        }
    }

    .setup-complete-header-title {
        height: 30px;
    }

    // Final step
    .setupCompleteSection {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 500px;

        .setupCompleteContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 700px;

            transition: all ease-in-out 1s;

            .SCVideoContainer {
                video {
                    width: 400px;
                }
            }

            .SCImageContainer {
                img {
                    width: 400px;
                }
            }

            &.hide {
                .SCImageContainer {
                    opacity: 0
                }
            }
        }
    }
}

.row {
    .cardColum {
        flex: auto;
    }
}

.top-header-custom {
    .title-one {
        flex-grow: 5;
    }

    .titleRate {
        position: relative;

        left: 40px;

        flex-grow: 1;

        text-align: center;
    }

    .titleFee {
        flex-grow: 1;
        justify-content: center;
        padding-left: 0px !important;

    }
}

.small-tab-customer-reg {
    height: 36px;

    li {
        height: 37px;
    }
}

.set-basic-subheading {
    width: 75%;

    li:nth-child(2) {
        width: 9%;
    }
}

.set-basic-table-head {
    background-color: #307aff !important;
    border: 0 !important;

    th {
        color: white !important;
        font-weight: 500 !important;

        border-bottom: 0 !important;
    }

    th:nth-child(1) {
        padding-right: 63px !important;

        text-align: center;
    }

    th:nth-child(2) {
        padding-left: 20px !important;
    }
}

/* stylelint-disable-next-line */
.table-blue-header {
    background-color: #307aff !important;

    /* stylelint-disable-next-line */
    tr {

        /* stylelint-disable-next-line */
        th {
            color: white !important;
        }
    }
}

.big-deatil-tabs {
    .main-tab-div-section {
        display: flex;
        grid-gap: 50px;
        padding: 0;

        background: #fff;
        border-radius: 10px;
    }

    .card-rate {
        margin-bottom: 1rem !important;

        padding: 0 0 14px;

        color: #000;

        border-bottom: none;

        li {
            a {
                color: $primaryColor;
            }

            a.active {
                position: relative;

                &:before {
                    position: absolute;
                    right: 0;
                    bottom: -14px;
                    left: 0;

                    display: inline-block;
                    height: 2px;

                    background: $primaryColor;

                    content: "";
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    @import "../components/customerRegistrationPage/navTabsSection";
    /* stylelint-disable-next-line */
    @import "../components/customerRegistrationPage/mccTurnoverSection";
}

.services-css {
    .title-one {
        width: 16%;

        color: $primaryColor;
        font-weight: 500;

        text-align: center;
    }

    .custom-css-value {
        padding-right: 13px;

        text-align: unset;
    }

    .custom-css-fee {
        width: 12%;
        padding-left: 5px;

        text-align: unset;
    }

    .custom-tooltip-dropdown {
        width: 7%;

        text-align: unset;
    }

    .description-style {
        width: 35%;

        text-align: unset;
    }
}

/* stylelint-disable-next-line */
.customer-registration-section {

    .card {
        width: 100%;
        margin-top: 16px;
        padding: 10px 0;

        border: none;
        border-radius: 0;

        &.cardPrimary {
            padding: 10px;

            color: $whiteColor;

            background: $primaryColor;
            border: 1px solid $primaryColor;
            border-radius: 10px;
        }

        &.pbl {
            .input-div {
                width: 28% !important;

                column-gap: 59px !important;
            }
        }


        &.cardRate {
            margin-top: 0;
        }

        .cardTitle {
            margin: 10px 0;
            padding: 0;

            font-size: 100%;
            font-family: Roboto, sans-serif;

            border: 0;
        }

        .cardBody {
            p {
                font-weight: normal;
                font-size: 12px;
            }

            .main-group {
                display: inline-flex;
                align-items: center;
                width: 20%;
                padding: 18px 15px 15px 0;

                background: transparent;
                border-radius: 12px;
                box-shadow: none;

                label {
                    margin-left: 5px;
                }
            }
        }
    }

    .customer-header {
        display: flex;
        justify-content: space-between;

        .customer-registration-cms {
            margin-top: 10px;
            margin-bottom: 30px;

            font-size: 17px;
        }

        .right {
            color: #307aff;

            /* stylelint-disable-next-line */
            p {
                padding: 10px;

                font-size: 14px;

                background-color: #fff;

                border: 1px solid #dfdfdf;
            }
        }
    }

    .nav-tabs.parent-tab {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 0px;
        padding: 0;

        background: rgb(246, 246, 246);
        border-width: 0;

        .nav-link {
            justify-content: center;
            height: 100%;
            margin-bottom: -1px;
            padding: 7px 10px;

            color: $primaryColor;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-align: center;

            background: $whiteColor;
            border: 1px solid $primaryColor;
            box-shadow: none;
        }

        .nav-link.active {
            position: relative;

            color: $whiteColor;

            background: $primaryColor;
            border: 1.5px solid $primaryColor;
            box-shadow: none;
        }
    }

    .border-tab-section {
        min-height: 500px;

        background: $whiteColor;
        border: 1px solid #e2e2e2;
        box-shadow: none;
    }

    .table-responsive {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        overflow: inherit;
    }

    div#tabs-1 {
        .tabel-section {
            .inner-tabel-tab {
                padding-top: 0;

                .inner-spacing-tabbing {
                    margin-bottom: 12px;

                    h2 {
                        margin-bottom: 16px;
                    }

                    .table-striped {
                        margin-bottom: -12px;

                        thead {
                            tr {
                                th {

                                    &:nth-child(6),
                                    &:nth-child(7),
                                    &:nth-child(8) {
                                        text-align: center;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {

                                    &:nth-child(8),
                                    &:nth-child(9),
                                    &:nth-child(10),
                                    &:nth-child(11) {
                                        text-align: center;
                                    }

                                    .last-main-div {
                                        input[type="number"]::-webkit-inner-spin-button {
                                            opacity: 1;
                                        }

                                        .ng-has-value {
                                            position: relative;

                                            width: 58px;
                                            height: 25px;
                                            min-height: 25px;
                                            //margin-left: 10px;
                                            overflow: visible;

                                            border-radius: 0;

                                            span[title="Clear all"] {
                                                position: absolute;
                                                top: 2px;
                                                right: -30px;

                                                text-align: center;
                                            }
                                        }

                                        .ng-arrow-wrapper {
                                            display: flex;
                                            flex-wrap: wrap;
                                            align-items: center;
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    div#tabs-2 {
        .tabel-section {
            .inner-tabel-tab {
                padding-top: 0;
            }
        }
    }

    div#tabs-8 {
        padding: 16px 0 0;
    }



    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            max-height: 272px !important;

            .ng-option {
                .ng-option-label {
                    font-size: 11px !important;
                }
            }
        }
    }
}

.tab-content.border-tab-section {
    .tab-pane {
        padding: 0;

        background: #fff;

        @media (max-width: 768px) {
            padding: 20px 0;
        }
    }

    .first-tab-form {
        padding: 0;
    }
}

.first-tab-section-div {

    /* stylelint-disable-next-line */
    .first-tab-form {
        .form-group {

            /* stylelint-disable-next-line */
            .main-group {
                width: 100%;
                padding: 9px 20px;

                font-size: 12px;

                border: 1px solid #d7d7d6;
                border-radius: 0;
                box-shadow: none;

                /* stylelint-disable-next-line */
                &:hover {
                    background: #f6f6f6;
                    cursor: pointer;
                }
            }

            /* stylelint-disable-next-line */
            label {
                grid-gap: 10px;
                margin-left: 15px;

                font-weight: normal;

                /* stylelint-disable-next-line */
                &:after {
                    left: -33px !important;
                }
            }
            /* stylelint-disable-next-line */
            input {
                margin-right: 10px;
            }
        }

        h3 {
            margin-bottom: 25px;

            font-weight: 400;
            font-size: $titleFontSize;
        }

        /* stylelint-disable-next-line */
        p {
            margin-bottom: 25px;

            font-weight: 500;
            font-size: 12px;
        }
    }

    .first-tab-form.fifth-tab-form {

        /* stylelint-disable-next-line */
        .form-group {

            /* stylelint-disable-next-line */
            .main-group {
                width: 100%;
                margin-bottom: 5px;
                padding: 10px 15px;

                font-size: 12px;

                border: none;
            }
        }
    }
}

.bottom-section-btn {
    .next-btn-section {
        &[disabled] {
            background: #ccc;
            border: 1px solid #ccc;
        }
    }

    //  padding: 16px 0;

    text-align: right;
}

/* stylelint-disable-next-line */
.first-tab-form {
    padding: 40px 80px 20px 80px;

    /* stylelint-disable-next-line */
    h2 {
        margin-bottom: 25px;

        font-weight: bold;
        font-size: 18px;
    }

    /* stylelint-disable-next-line */
    p {
        margin-bottom: 20px;

        font-weight: 500;
        font-size: 14px;
    }

    /* stylelint-disable-next-line */
    .form-group {
        display: block;
        margin-bottom: 11px;

        /* stylelint-disable-next-line */
        label {
            position: relative;

            display: inline-flex;
            align-items: center;

            font-size: $regularFontSize;

            cursor: pointer;
        }

        /* stylelint-disable-next-line */
        input {
            position: relative;

            display: inline-block;
            // float: right;

            margin-right: 5px;
            padding: 5px;

            vertical-align: middle;

            background-color: transparent;
            border: 1px solid #343434;
            border-radius: 2px;
            cursor: pointer;

            content: "";
        }

        /* stylelint-disable-next-line */
        .main-group {
            display: inline-block;
            width: 30%;
            padding: 18px 15px;

            background: white;
            border-radius: 12px;
            box-shadow: 3px 2px 11px -4px #888888;
        }
    }

    .inner-section-tabing {
        margin-bottom: 40px;
    }
}

.fourth-tab-form.first-tab-form {

    /* stylelint-disable-next-line */
    .form-group {

        /* stylelint-disable-next-line */
        label {
            font-weight: 500;
        }
    }
}

.common-btn-section {
    width: 150px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 2px 15px;

    color: #fff;
    font-weight: 400;
    font-size: 11px;
    line-height: 20.12px;
    text-transform: initial;

    background: #307aff;

    border: 1px solid #307aff;
    border-radius: 5px;
    box-shadow: none;

    &:hover {
        background: #2a6cdd;
    }

    &.success {
        background-color: $successColor !important;
        border: 1px solid $successColor !important;
    }

    &.error {
        background-color: $errorColor !important;
        border: 1px solid $errorColor !important;
    }
}

.negative-btn {
    color: #4c4c4c !important;

    background: #f6f6f6 !important;
    border: 1px solid #d8d8d8 !important;
    box-shadow: none !important;

    &:hover {
        background: #efefef !important;
    }
}

/* stylelint-disable-next-line */
.last-main-div {
    display: flex;
    align-items: center;

    /* stylelint-disable-next-line */
    input {
        width: 50px;
        padding: 4px 0 4px 12px;

        text-align: center;

        border: 1px solid #dfdfdf;

        &.num-input {
            padding-left: 0;
        }
    }

    .motoPosition {
        margin-left: -80px;
    }

    /* stylelint-disable-next-line */
    span {
        margin: auto 0;
    }

    /* stylelint-disable-next-line */
    select {
        position: relative;

        width: 58px;
        height: 20px;

        padding: 4px 15px;

        background-color: #fff;

        border: 1px solid #ccc;

        &:focus-visible {
            outline: none;
        }

    }

    .button-section {
        display: flex;
        grid-gap: 5px;
        align-items: center;
        width: 40%;

        .pluse-btn {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 20px;
            height: 20px;

            color: #fff;
            font-size: 14px;

            background: #7c7c7c;
            border-radius: 50%;
        }

        .minus-btn {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 20px;
            height: 20px;

            color: #fff;
            font-size: 14px;

            background: #7c7c7c;
            border-radius: 50%;
        }
    }
}

.popover__wrapper {
    position: relative;

    &.category-2 {
        // margin-left: 19px;
    }

    /* stylelint-disable-next-line */
    .last-main-div {
        display: flex;
        grid-gap: 5px;
        align-items: center;

        /* stylelint-disable-next-line */
        input[type="number"]::-webkit-inner-spin-button {
            opacity: 1;
        }

        /* stylelint-disable-next-line */
        input {
            width: 60px;
            padding: 3px 0 3px 0;

            text-align: center;

            border: 1px solid #dfdfdf;
        }

        /* stylelint-disable-next-line */
        select {
            width: 50px;
            padding: 4px 0;

            text-align: center;

            border: 1px solid #dfdfdf;
        }

        .button-section {
            display: flex;
            grid-gap: 5px;
            align-items: center;
            width: 40%;

            .pluse-btn {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 20px;
                height: 20px;

                color: #fff;
                font-size: 14px;

                background: #7c7c7c;
                border-radius: 50%;
            }

            .minus-btn {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 20px;
                height: 20px;

                color: #fff;
                font-size: 14px;

                background: #7c7c7c;
                border-radius: 50%;
            }
        }
    }
}

.popover__content {
    position: absolute;

    top: 25px;

    right: -160px;

    width: 220px;

    padding: 11px;

    background-color: #fff;

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

    transform: translate(0, 10px);

    visibility: hidden;

    opacity: 0;

    .popover__message {
        margin: 0;

        text-align: center;
    }
}

.svg-content {
    right: -165px !important;
}

.popover__content:before {
    position: absolute;
    top: -8px;
    left: 86px;
    z-index: -1;

    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 10px 10px 10px;

    transition-duration: 0.3s;
    transition-property: transform;

    content: "";
}

.svg-content:before {
    left: 18px !important;

    @media (min-width: 1600px) and (max-width: 1920px) {
        left: 4px !important;
    }
}

.popover__wrapper:hover .popover__content {
    z-index: 10;

    transform: translate(0, -10px);
    visibility: visible;
    opacity: 1;

    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.turnover-main-container {
    width: 100%;
    margin: 0 auto;

    /* stylelint-disable-next-line */
    .ng-select {
        position: relative;

        /* stylelint-disable-next-line */
        .ng-select-container {
            height: 46px;
            overflow: visible;

            border-radius: 0;

            /* stylelint-disable-next-line */
            span[title="Clear all"] {
                position: absolute;
                top: 12px;
                right: -28px;
            }

            .ng-input {
                top: 0 !important;
            }

            .ng-value-container {
                .ng-value {
                    color: $blackColor;
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    label {
        display: block;
        width: 100%;

        color: $headerTextColor;
        font-size: 12px;
    }

    /* stylelint-disable-next-line */
    input {
        display: block;
        width: 100%;
        height: 40px;
        padding: 8px 15px;

        color: $blackColor;
        font-size: 12px;

        border: 1px solid #d7d7d6;
    }

    .note {
        margin-top: -40px;

        color: #7c7c7c;

        font-size: 10px;
    }

    .turnover-div-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        .left_section {
            width: 48%;

            /* stylelint-disable-next-line */
            .turnover-div-main {
                margin: 0;
            }

            /* stylelint-disable-next-line */
            label {
                display: block;
                width: 100%;

                color: $headerTextColor;
                font-size: 12px;
            }
            /* stylelint-disable-next-line */
            input {
                display: block;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 2px;
                padding: 8px 15px;

                color: $blackColor;

                font-size: 12px;

                border: 1px solid #d7d7d6;
            }
        }

        .right_section_mcc {
            width: 48%;
        }
    }
}

/* stylelint-disable-next-line */
h2 {
    color: #4C4C4C;
    font-weight: 400;
    font-size: $regularFontSize;
}

.calculator-heading-div {
    display: flex;
    justify-content: space-between;

    background-color: #307aff;

    button {
        margin-right: 16px;

        color: white;
        font-size: x-large;

        background-color: #307aff;
        border: 0;
    }
}

/* stylelint-disable-next-line */
@import "../components/customerRegistrationPage/cardRateSection";

.lower-table {
    .top-title-section {
        margin-top: 40px;
        margin-bottom: 8px;
        padding-left: 15px;

        font-weight: 500;
        font-size: 14px;
    }

    /* stylelint-disable-next-line */
    .extra-padding {
        padding: 6.41px 20px 6.41px 20px !important;
    }

    .services-css {
        grid-gap: 50px !important;
        width: 100% !important;
    }

    .table2 {
        grid-gap: 368px;
        justify-content: unset;
        width: 83%;

        @media (max-width: 991px) {
            width: 46%;
        }

        .card-header {
            display: flex;
            width: 100%;
            padding: 6.41px 20px;

            font-size: 11px;
            line-height: 15px;

            background: transparent;

            .right-content-card-rate {
                display: flex;
                grid-gap: 195px;
                align-items: center;
                justify-content: space-between;

                width: 100%;

                padding-left: 115px;

                /* stylelint-disable-next-line */
                .div {
                    display: flex;
                    grid-gap: 15px;
                    align-items: center;
                    justify-content: space-between;

                    width: 110px;
                }

                /* stylelint-disable-next-line */
                input {
                    &.ng-untouched {
                        margin: 0 32px 0 0;
                    }

                    &[formcontrolname="transactionFee"] {
                        margin: 0 25px;
                    }

                    @media (max-width: 1600px) {
                        margin: 0 38px;
                    }

                    @media (max-width: 991px) {
                        margin: 0 36px;
                    }

                    @media (max-width: 480px) {
                        margin: 0 3px;
                    }

                    width: 50px;
                    margin: 0 35px;

                    padding: 4px 5px;

                    text-align: center;

                    border: 1px solid #dfdfdf;
                }

                /* stylelint-disable-next-line */
            }
        }
    }

    .table2-body {
        .card-header {
            display: flex;
            grid-gap: 50px;
            align-items: center;

            width: 100%;
            height: 41px;
            padding: 6.41px 20px;

            font-size: 11px;
            line-height: 15px;

            background: transparent;
            border-bottom: 1px solid #dfdfdf;

            .name-css {
                width: 12%;
                margin: 6px 0;

                text-align: unset;
            }

            .svg-dropdown-css {
                width: 7%;
                margin: 6px 0;

                text-align: unset;
            }

            .dropdown-css {
                width: 7%;

                text-align: unset;
            }

            .description-style {
                width: 35%;
                margin: 6px 0;

                text-align: unset;
            }

            .left-content {

                /* stylelint-disable-next-line */
                h2 {
                    padding-top: 6px;
                }
            }

            .right-content-card-rate {
                display: flex;
                grid-gap: 220px;

                align-items: center;

                padding-left: 115px;

                /* stylelint-disable-next-line */
                .div {
                    display: flex;
                    grid-gap: 15px;
                    align-items: center;
                    justify-content: space-between;

                    width: 110px;
                }

                /* stylelint-disable-next-line */
                input {
                    &.ng-untouched {
                        margin: 0 32px 0 0;
                    }

                    &[formcontrolname="transactionFee"] {
                        margin: 0 25px;
                    }

                    @media (max-width: 1600px) {
                        margin: 0 38px;
                    }

                    @media (max-width: 991px) {
                        margin: 0 36px;
                    }

                    @media (max-width: 480px) {
                        margin: 0 3px;
                    }

                    width: 50px;
                    margin: 0 35px;

                    padding: 4px 5px;

                    text-align: center;

                    border: 1px solid #dfdfdf;
                }

                /* stylelint-disable-next-line */
            }
        }
    }
}

.top-header-section {
    display: flex;
    // grid-gap: 50px;
    padding: 8px 46px 8px 46px;

    font-size: 12px;
    line-height: 19.2px;

    background: #307aff;
    border: 1px solid #dee2e6;

    @media (max-width: 991px) {
        grid-gap: 0;
        width: 150%;
    }

    .titleOne {
        flex-grow: 6;

        color: white;
        font-weight: 500;
    }

    .titleTwo {
        position: relative;
        left: 72px;

        flex-grow: 1;

        color: white;
        font-weight: 500;

    }

    .titleThree {
        position: relative;
        left: 84px;

        flex-grow: 1;

        color: white;
        font-weight: 500;

    }

    .title-four {
        color: white;
        font-weight: 500;
        text-align: center;
    }

    .title-five {
        color: white;
        font-weight: 500;
        text-align: center;
    }
}

.applicant_details {
    @media (max-width: 480px) {
        padding: 20px !important;
    }

    .main-tab-div-section {
        display: grid !important;
        grid-template-columns: 0.8fr 1fr;

        /* stylelint-disable-next-line */
        .main-group {

            /* stylelint-disable-next-line */
            label {

                /* stylelint-disable-next-line */
                &:after {
                    right: -47px;
                    left: initial;
                }
            }
        }

        /* stylelint-disable-next-line */
        .right {

            /* stylelint-disable-next-line */
            input:not([type="radio"]):not([type="text"]) {
                padding: 0 45px !important;
            }
        }
    }
}

.address-div {

    .google-map-container {
        position: relative;

        display: block;
        width: 100%;

        .map {
            width: 100%;

            border: 1px solid $primaryColor;
            opacity: 0;
        }
    }

    $animationDelay: 1;

    .show-map .map {
        border: 1px solid $primaryColor;

        animation-name: fade-in;
        animation-duration: 5s;
        animation-fill-mode: both;
    }

    .show-map.hide .map {
        animation-name: fade-out;
        animation-duration: 5s;
        animation-fill-mode: both;
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    /* stylelint-disable-next-line */
    .map img {
        cursor: default !important;
    }

    img[alt="Google"],
    a[rel="noopener"],
    .gmnoprint {
        display: none !important;
    }

    input+.invalid-feedback {
        margin-top: -5px;
        margin-bottom: 20px;
    }
}

.customer_contact_section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;

    .customer_registration {
        width: 100%;
        margin: 0;
    }

    .c-c {
        padding: 0 !important;
    }
}

/* stylelint-disable-next-line */
@import "../components/customerRegistrationPage/businessTypeSection";

.sub-tab-heading>ul {
    border: 0 !important;
}

/* stylelint-disable-next-line */
@import "../components/customerRegistrationPage/customerRegistrationSection";
/* stylelint-disable-next-line */
@import "../components/customerRegistrationPage/setupCompleteSection";
/* stylelint-disable-next-line */
@import "../components/customerRegistrationPage/cardFeeSection";

.summary-auth-fee-val {
    display: inline-block;
    width: 31px;
    padding: 5px;

    color: black;

    text-align: center;

    background-color: white;
    border-radius: 1px;
}

.summary-auth-fee {
    justify-content: flex-end;
    height: 32px;

    font-size: 11px;

    border-radius: 1px;
}

.authorisation-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 15px;

    border-top: 2px solid $primaryColor;
    border-bottom: 2px solid $primaryColor;

    /* stylelint-disable-next-line */
    .right-content {

        /* stylelint-disable-next-line */
        h2 {
            font-weight: 500;
            font-size: 11px;
        }
    }

    /* stylelint-disable-next-line */
    .authorisationInput {
        width: 20%;
    }

    .inputLeft {
        left: -61px !important;
    }

    /* stylelint-disable-next-line */
    .input-div {
        position: relative;
        left: -40px;


        /* stylelint-disable-next-line */
        input {
            width: 50px;

            font-size: 11px;
            text-align: center !important;

            background-color: white;
            border: 1px solid #dfdfdf;
        }

        .fee-input-box {
            padding: 2px 0px;

            outline: none;
        }
    }
}

.hr-tag-line {
    margin-top: 4rem;

    border-top: 2px solid gray;
}

/* stylelint-disable-next-line */
.tool:hover .tooltiptext {
    visibility: visible;
}

/* stylelint-disable-next-line */
.tool .tooltiptext {
    position: absolute;
    top: 18px;
    right: -30px;
    z-index: 1;

    min-width: 80px;
    max-width: 100px;
    padding: 5px 0;

    color: $White-clr;
    font-size: 12px;
    text-align: center;

    background-color: $Black-clr;
    border-radius: 3px;
    visibility: hidden;
}

/* stylelint-disable-next-line */
.tool {
    position: relative;

    display: inline-block;
}

.authFeePosition {
    top: -16px !important;
}

/* stylelint-disable-next-line */
.card-header {
    .cell-css {
        width: 16%;

        text-align: center;

        /* stylelint-disable-next-line */
        input {
            width: 50px;
            padding: 4px 0 4px 12px;

            text-align: left;

            border: 1px solid #dfdfdf;
        }
    }

    .custom-cell-css {
        display: flex;

        .charge-label-css {
            margin: 4px 5px;
        }
    }
}

.lower-table.services-table.col-md-10 {
    margin-top: 0 !important;
}

h2.top-title-section.services-title {
    margin-bottom: 16px;
    padding-left: 0;

    font-weight: 400;
    font-size: $titleFontSize;
}

.services-title-description {
    margin-bottom: 16px;

    /* stylelint-disable-next-line */
    p {
        font-size: 12px;
    }
}

.transaction-title-css {
    // padding-left: 72px;
}

.feedback-override {
    width: 135px !important;

    text-align: left;
}

.error-msg-style {
    border-color: red !important;
}

.error-msg-style:focus-visible {
    outline: red auto 1px !important;
}



.transaction-section {
    display: flex;
    align-items: center;

    // &.fee{
    //     margin-left:20px;
    // }

    &.ng-star-inserted {
        margin-left: 30px;
    }
}

.service-fee-section {
    display: flex;
}

.remove-crusor-blink {
    caret-color: transparent;
}

.override_position_rel {
    position: relative;
}

.override_position_ab {
    position: absolute;
    top: 0;
    left: 5px;

    width: 95px;
}

.service-input-override {
    width: 14% !important;
}

.fee-value-input-height {
    height: 22px;
}

.select-header-override {
    text-align: end;
}

.product-image-firstTab {
    width: 40px;
}

.font-size-ul {
    font-size: 12px !important;
}

.sole-employee-p {
    margin: 0;
    padding: 0;

    font-size: 14px;
    line-height: 0;
}

.sole-employee-info {
    margin-bottom: 30px;
}

.transaction100Msg {
    color: red;
    font-size: smaller;
}

.fee-input-box::-webkit-outer-spin-button,
.fee-input-box::-webkit-inner-spin-button {
    display: none;
}

.remove-spinner {
    width: 20px !important;

    border: 0 !important;
    outline: none;
}

.remove-spinner:focus {
    border: 0 !important;
}

.ooveride {
    width: 35px !important;
    padding-left: 3px;

    border: 0 !important;
}

.feeCheckbox {
    width: 32px !important;

    padding-left: 3px;
}

.ooveride:focus {
    border: 0 !important;
    outline: none;
}

.basic-price {
    width: 42px !important;
    padding-left: 3px;

    border: 0 !important;

    :focus {
        border: 0 !important;
        outline: none;
    }
}

.cardMargin {
    margin-left: -7px;
}

/* stylelint-disable-next-line */
.fee-input-box {
    display: flex;
    align-items: center;

    background-color: #fff;
    border: 1px solid #dfdfdf;

    .feeMargin {
        margin-left: -2px;
    }
}

.rate-main-text .last {
    margin-right: 35px;
}

.rate-main-text {
    display: flex;
    align-items: center;
    // margin-right:35px;

    &.disabled {
        &.customIncDecContainer {
            .fee-input-box {
                opacity: .7;
            }
        }
    }
}


.small-font-setup_complete {
    font-weight: 500;
    font-size: smaller;
}

.home-address-weight {
    font-weight: 500;
}

.top-next-boarding-btn {
    margin-top: 10px;
    margin-right: 0;
}

.top-next-cardrate-btn {
    margin-top: -13px;
}

.top-next-mcc-btn {
    display: flex;
    justify-content: flex-end;
    margin-right: -38px;
}

.top-next-contact-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    padding-right: 35px;
}

.sub-tab-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.boarding-totalcost-msg {
    padding-right: 31px;

    color: red;
    font-weight: 500;
    font-size: 13px;
    text-align: right;
}

.business-type-req-txt {
    margin-top: 0.25rem;
    margin-bottom: 15px;

    color: red;
    font-size: 13px;
}

.circle-plus {
    width: 11px;
    margin-left: 6px;

    cursor: pointer;
}

.circle-minus {
    width: 11px;
    margin-right: 10px;

    cursor: pointer;
}

.svg-q-icon_fee {
    margin: 0 10px;
}

.svg-q-icon {
    margin-left: 10px;

}

.questionMark {
    position: absolute;

    width: 14px;


}


.transaction-fee-dropdown {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 54px;
    padding-right: 20px;
    padding-left: 6px;
    background-image: url("../../img/dropdown_icon.png");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    border: 1px solid #dfdfdf;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


.auth-fee-input {
    height: 24px;

    font-size: 11px;
}

.sub-tab-cardrate {
    font-size: 14px;
}

.bottom-section-border {
    border-top: none;
}

.set-basic-head-txt {
    color: $primaryColor;
    font-weight: 500;
    font-size: 13px;
}

.select-product-txt {
    font-size: $titleFontSize;
}

.accessory-first-td {
    display: flex;
    align-items: center;

    border: 0 !important;
}

.subtab-txt_set-basic {
    font-size: smaller !important;
}

.define-mcc-custom {
    display: block;
    width: 100%;
    height: 40px;
    padding: 8px 15px;

    color: #7c7c7c;
    font-size: 12px;

    border: 1px solid #d7d7d6;
}

.card-group-info {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 52px;

    background: #f6f6f6;
}

.card-info-body {
    padding: 2px;
}

.top-section-btn,
.bottom-section-btn {
    margin: $CRSectionSpacing 0;

    text-align: right;

    .cancel-btn-sec {
        margin-top: 16px;
        margin-right: 10px;
    }
}

.invalid-identity-check {
    color: red;
    font-size: 12px;
}

.businessInformationComponent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
}

// .businessInformationQuestions {
//     display: flex;
//     justify-content: space-between;

//     min-height: 500px;
// }

.visibilty-hidden {
    visibility: hidden;
}

.size-setting-cardrate {
    width: 40%;
}

.service-monthly-price {
    display: flex;
    justify-content: space-around;
}

.feeBox {
    display: flex;
    align-items: center;

    width: 61px;
    height: 25px;

    padding-right: 10px;

    column-gap: 8px;

    background-color: #fff;
    border: 1px solid $Grey-clr;

    outline: none;
}

.serviceRowHeight {
    height: 38px;
}

.businessDescriptionTextArea {
    padding: 15px;
}

.leftSection {
    margin-left: 15px;
}

.rightSections {
    margin-right: 15px !important;
}

/* stylelint-disable-next-line */
.is-free-checkbox-container {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

/* stylelint-disable-next-line */
.is-free-checkbox-container label {
    margin-left: 2px;
}

.is-freePriceLabel {
    color: #307aff;
    font-weight: 500 !important;
    font-size: 100%;
}

.pciQuantity {
    width: 58px;

    height: 20px;

    padding: 3px 0 3px 0;

    text-align: center;

    border: 1px solid #dfdfdf;
}

.turnover_main {
    .turnover-main-container {
        .borderGray {
            border: 1px solid #d7d7d6;
            border-bottom: 0 none;
        }
    }
}

.loadRowData {
    padding: 50px 500px 50px 550px;

    border-bottom: none !important;

    .loadData {
        height: 250px !important;

        border-bottom: none;
    }
}

.amexSection{
    height: 318px;
    margin-left: 17px;
}


.pos-products-summary-tabs {
    padding: 15px;
    border: 1px solid #e2e2e2;

    ul {
        margin-bottom: 10px;

        li {
            border: 1px solid #307AFF;
            margin: 15px;

            a {
                font-size: 12px;
                color: black;

                &:hover, &.active {
                    background: #307AFF !important;
                    color: white;
                }
            }
        }
    }
}
